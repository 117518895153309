<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <SmartTable ref="assicurazioniTesserato" :has_checkboxes="false" :is_dialog="true" url="/enrolled-user-insurance/index" base_url="/enrolled-user-insurance" base_path="/enrolled-user-insurance" v-on:values-saved="updateEnrolledUsersInsurance" :fixed_filters="[{ name: 'idenrolleduser', filter: idenrolleduser }]" v-on:values-updated="updateEnrolledUsersInsurance" v-on:values-deleted="updateEnrolledUsersInsurance" custom_class="mx-0 px-0">
            <template v-slot:title><span></span></template>

            <template v-slot:item-actions="slotProps">
                <td class="text-nowrap pr-0 text-right">
                    <a v-if="slotProps.values.item.can_upgrade" href="#" @click.prevent="upgradeInsurance(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Upgrade Assicurativo" alt="Upgrade Assicurativo">
                        <i class="far fa-thumbs-up"></i>
                    </a>

                    <b-modal ref="upgradeInsuranceModal" size="lg" hide-footer title="Upgrade Assicurazione">
                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                            <form>
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nuova Assicurazione:</label>
                                    <div class="col-md-8">
                                        <select v-model="idnewinsurance" class="form-control">
                                            <option value="">Seleziona un'assicurazione</option>
                                            <option v-for="option in insurances" :value="option.id">{{option.label}}</option>
                                        </select>
                                    </div>
                                </div>
                            </form>

                            <div class="d-flex justify-content-end">
                                <b-button class="mt-3" variant="danger" @click.prevent="hideUpgradeInsuranceModal">Annulla</b-button>
                                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confirmUpgradeInsuranceModal">Conferma</b-button>
                            </div>
                        </b-overlay>
                    </b-modal>

                    <a v-if="slotProps.values.item.can_downgrade" href="#" @click.prevent="downgradeInsurance(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Downgrade Assicurativo" alt="Downgrade Assicurativo">
                        <i class="far fa-thumbs-down"></i>
                    </a>

                    <b-modal ref="downgradeInsuranceModal" size="lg" hide-footer title="Downgrade Assicurazione">
                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                            <form>
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nuova Assicurazione:</label>
                                    <div class="col-md-8">
                                        <select v-model="idnewinsurance" class="form-control">
                                            <option value="">Seleziona un'assicurazione</option>
                                            <option v-for="option in insurances" :value="option.id">{{option.label}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="$store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin'" class="form-group row">
                                    <label class="col-md-4 col-form-label">Riaccredito:</label>
                                    <div class="col-md-8">
                                        <b-form-radio-group
                                            id="riaccreditoflag"
                                            v-model="riaccreditoflag"
                                            :options="[{ text: 'Si', value: 1 }, { text: 'No', value: 0 }]"
                                        >
                                        </b-form-radio-group>
                                    </div>
                                </div>
                            </form>

                            <div class="d-flex justify-content-end">
                                <b-button class="mt-3" variant="danger" @click.prevent="hideDowngradeInsuranceModal">Annulla</b-button>
                                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confirmDowngradeInsuranceModal">Conferma</b-button>
                            </div>
                        </b-overlay>
                    </b-modal>

                    <a v-if="slotProps.values.item.can_change_date" href="#" @click.prevent="changeStartDateInsurance(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Cambia Inizio data Assicurazione" alt="Cambia Inizio data Assicurazione">
                        <i class="fas fa-clock"></i>
                    </a>

                    <b-modal ref="changeStarDateInsuranceModal" size="lg" hide-footer title="Cambio Inizio Data Assicurazione">
                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                            <form>
                                <div class="form-group row">
                                    <label class="col-md-4 col-form-label">Nuovo Inizio Data Assicurazione:</label>
                                    <div class="col-md-8">
                                        <date-picker v-model="newinsurancestartdate" type="datetime" placeholder="Seleziona una data" format="DD/MM/YYYY HH:mm:ss" time-title-format="DD/MM/YYYY" value-type="YYYY-MM-DD HH:mm:ss" input-class="mx-input" :shortcuts="date_shortcuts"></date-picker>
                                    </div>
                                </div>
                            </form>

                            <div class="d-flex justify-content-end">
                                <b-button class="mt-3" variant="danger" @click.prevent="hideChangeStartDateInsuranceModal">Annulla</b-button>
                                <b-button class="ml-3 mt-3" variant="success" @click.prevent="confirmStartDateInsuranceModal">Conferma</b-button>
                            </div>
                        </b-overlay>
                    </b-modal>

                    <a v-if="slotProps.values.item.can_stop" href="#" @click.prevent="stopInsurance(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Ferma Assicurazione" alt="Ferma Assicurazione">
                        <i class="far fa-stop-circle"></i>
                    </a>

                    <a href="#" @click.prevent="slotProps.values.doItemAction({ name: 'view', label: 'Dettagli', url: 'view' }, slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Visualizza" alt="Visualizza">
                        <i class="fas fa-eye"></i>
                    </a>
                </td>
            </template>
        </SmartTable>
    </b-overlay>
</template>

<script>
 import ApiService from "@/core/services/api.service";
 import SmartForm from "@/view/components/SmartForm.vue";
 import SmartTable from "@/view/components/SmartTable.vue";
 import Swal from 'sweetalert2';

 export default {
     name: 'EnrolledUserInsurance',

     props: ['idenrolleduser'],

     data() {
         return {
             loading: false,
             idenrolleduserinsurance: null,
             idnewinsurance: '',
             riaccreditoflag: 1,
             newinsurancestartdate: null,
             insurances: [],

             date_shortcuts: [
                 {
                     text: 'Oggi',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         return item;
                     },
                 },
                 {
                     text: 'Ieri',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         item.setTime(item.getTime() - 3600 * 1000 * 24);

                         return item;
                     },
                 },
                 {
                     text: 'Ultima settimana',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         item.setTime(item.getTime() - 3600 * 1000 * 24 * 7);

                         return item;
                     },
                 },
                 {
                     text: 'Ultimo mese',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         item.setTime(item.getTime() - 3600 * 1000 * 24 * 30);

                         return item;
                     },
                 },
                 {
                     text: 'Ultimi 3 mesi',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         item.setTime(item.getTime() - 3600 * 1000 * 24 * 90);

                         return item;
                     },
                 },
                 {
                     text: 'Ultimi 6 mesi',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         item.setTime(item.getTime() - 3600 * 1000 * 24 * 180);

                         return item;
                     },
                 },
                 {
                     text: 'Ultimo anno',
                     onClick() {
                         const date = new Date();

                         const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                         item.setTime(item.getTime() - 3600 * 1000 * 24 * 365);

                         return item;
                     },
                 },
             ],
         }
     },

     computed: {
     },

     mounted() {
     },

     components: {
         SmartForm,
         SmartTable,
     },

     methods: {
         upgradeInsurance(id) {
             this.loading = true;
             this.idenrolleduserinsurance = id;
             let url = '/enrolled-user-insurance/get-insurances/upgrade/' + id;
             ApiService.query(url)
                       .then((response) => {
                           this.insurances = response.data.insurances;
                           this.idnewinsurance = '';

                           let vm = this;
                           setTimeout(function() {
                               vm.$refs['upgradeInsuranceModal'].show();
                           }, 200);

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         hideUpgradeInsuranceModal(id) {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['upgradeInsuranceModal'].hide();
             }, 200);
         },

         confirmUpgradeInsuranceModal() {
             this.loading = true;
             let url = '/enrolled-user-insurance/upgrade-insurance/' + this.idenrolleduserinsurance + '?idnewinsurance=' + this.idnewinsurance;
             ApiService.query(url)
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('L\'Assicurazione è stata aggiornata con successo', {
                                   title: 'Assicurazione aggiornata con successo',
                                   variant: 'success',
                                   solid: true
                               });

                               if (typeof response.data.cost !== "undefined" && response.data.cost) {
                                   Swal.fire({
                                       title: 'Assicurazione aggiornata con successo',
                                       text: "Sono stati addebbitati " + response.data.cost + " euro",
                                       icon: 'warning',
                                       showConfirmButton: true,
                                       confirmButtonText: 'Ok',
                                   });
                               }

                               this.insurances = [];

                               this.$refs['upgradeInsuranceModal'].hide();

                               this.idenrolleduserinsurance = null;
                               this.idnewinsurance = '',
                               this.updateEnrolledUsersInsurance();

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nell\'aggiornamento dell\'assicurazione', {
                                   title: 'Errori nell\'aggiornamento dell\'assicurazione',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         downgradeInsurance(id) {
             this.loading = true;
             this.idenrolleduserinsurance = id;
             let url = '/enrolled-user-insurance/get-insurances/downgrade/' + id;
             ApiService.query(url)
                       .then((response) => {
                           this.insurances = response.data.insurances;
                           this.idnewinsurance = '';

                           let vm = this;
                           setTimeout(function() {
                               vm.$refs['downgradeInsuranceModal'].show();
                           }, 200);

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         hideDowngradeInsuranceModal(id) {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['downgradeInsuranceModal'].hide();
             }, 200);
         },

         confirmDowngradeInsuranceModal() {
             this.loading = true;
             let url = '/enrolled-user-insurance/downgrade-insurance/' + this.idenrolleduserinsurance + '?idnewinsurance=' + this.idnewinsurance;

             if (this.$store.getters.currentUser.usertype === 'Segreteria Nazionale' || this.$store.getters.currentUser.usertype === 'Superadmin') {
                 url += '&riaccreditoflag=' + this.riaccreditoflag;
             }

             ApiService.query(url)
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('L\'Assicurazione è stata aggiornata con successo', {
                                   title: 'Assicurazione aggiornata con successo',
                                   variant: 'success',
                                   solid: true
                               });

                               if (typeof response.data.cost !== "undefined" && response.data.cost) {
                                   Swal.fire({
                                       title: 'Assicurazione aggiornata con successo',
                                       text: "Sono stati accreditati " + response.data.cost + " euro",
                                       icon: 'success',
                                       showConfirmButton: true,
                                       confirmButtonText: 'Ok',
                                   });
                               }

                               this.insurances = [];

                               this.$refs['downgradeInsuranceModal'].hide();

                               this.idenrolleduserinsurance = null;
                               this.idnewinsurance = '',
                               this.riaccreditoflag = 1,

                               this.updateEnrolledUsersInsurance();

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nell\'aggiornamento dell\'assicurazione', {
                                   title: 'Errori nell\'aggiornamento dell\'assicurazione',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         changeStartDateInsurance(id) {
             this.loading = true;
             this.idenrolleduserinsurance = id;
             let url = '/enrolled-user-insurance/get-start-date/' + id;
             ApiService.query(url)
                       .then((response) => {
                           this.newinsurancestartdate = response.data.insurrancestartdate;

                           let vm = this;
                           setTimeout(function() {
                               vm.$refs['changeStarDateInsuranceModal'].show();
                           }, 200);

                           this.loading = false;
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         hideChangeStartDateInsuranceModal(id) {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['changeStarDateInsuranceModal'].hide();
             }, 200);
         },

         confirmStartDateInsuranceModal() {
             this.loading = true;
             let url = '/enrolled-user-insurance/change-date-insurance/' + this.idenrolleduserinsurance + '?date=' + encodeURI(this.newinsurancestartdate);
             ApiService.query(url)
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('La data dell\'Assicurazione è stata aggiornata con successo', {
                                   title: 'Data dell\'Assicurazione aggiornata con successo',
                                   variant: 'success',
                                   solid: true
                               });

                               this.insurances = [];

                               this.$refs['changeStarDateInsuranceModal'].hide();

                               this.idenrolleduserinsurance = null;
                               this.newinsurancestartdate = null,

                               this.updateEnrolledUsersInsurance();

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nell\'aggiornamento della data dell\'assicurazione', {
                                   title: 'Errori nell\'aggiornamento della data dell\'assicurazione',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         stopInsurance(id) {
             this.idenrolleduserinsurance = id;

             this.loading = true;
             let url = '/enrolled-user-insurance/stop-insurance/' + this.idenrolleduserinsurance;
             ApiService.query(url)
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.$bvToast.toast('La data dell\'Assicurazione è stata interrotta con successo', {
                                   title: 'Data dell\'Assicurazione interrotta con successo',
                                   variant: 'success',
                                   solid: true
                               });

                               this.idenrolleduserinsurance = null;

                               this.updateEnrolledUsersInsurance();

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.$bvToast.toast('Si sono verificati degli errori nell\'interruzione della data dell\'assicurazione', {
                                   title: 'Errori nell\'interruzione della data dell\'assicurazione',
                                   variant: 'danger',
                                   solid: true
                               });

                               this.loading = false;
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           this.loading = false;
                       })
         },

         updateEnrolledUsersInsurance() {
             this.$refs.assicurazioniTesserato.refresh();
         },
     },
 };
</script>
