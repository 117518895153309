<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <SmartTable ref="tesseramentiRicerca" url="/sportingclub/enrolled-user/index" :is_dialog="true" base_url="/sportingclub/enrolled-user" base_path="/sportingclub/enrolled-user" :fixed_filters="[{ name: 'idsportingclub', filter: idsportingclub }, { name: 'pagetype', filter: 'conisubscriptions' }, { name: 'idannuality', filter: annualityid }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalTesseramenti">
            <template v-slot:title>
                <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Ricerca Tesseramenti</span></h2>
            </template>

            <template v-slot:td-idenrolledstate="slotProps">
                <td v-if="slotProps.values.field.is_visible_for_index">
                    <div class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                        <span v-if="slotProps.values.item.idenrolledstate === 'Attivo'" class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVO</span>

                        <span v-if="slotProps.values.item.idenrolledstate === 'Eliminato'" class="badge pill bg-danger mb-1" style="font-size: 0.9em;">ELIMINATO</span>

                        <span v-if="slotProps.values.item.idenrolledstate === 'In Bozza'" class="badge pill bg-info mb-1" style="font-size: 0.9em;">IN BOZZA</span>

                        <span v-if="slotProps.values.item.idenrolledstate === 'Inserito - Da Attivare'" class="badge pill bg-warning mb-1" style="font-size: 0.9em;">INSERITO - DA ATTIVARE</span>

                        <span v-if="slotProps.values.item.idenrolledstate === 'Terminato'" class="badge pill bg-dark mb-1" style="font-size: 0.9em;">SCADUTO</span>

                        <span v-if="slotProps.values.item.is_in_edit_state === true" class="badge pill bg-primary mb-1" style="font-size: 0.9em;">MODIFICA IN SOSPESO</span>

                        <span v-if="slotProps.values.item.coniaffiliationdate && slotProps.values.item.coniaffiliationdate !== '-'" class="badge pill bg-primary mb-1" style="font-size: 0.9em;"><i class="fas fa-satellite-dish text-white"></i> INVIATO AL CONI</span>

                        <span v-if="typeof slotProps.values.item.istaxcodeerrorflag !== 'undefined' && slotProps.values.item.istaxcodeerrorflag === 'Si'" class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i class="fas fa-window-close text-white"></i> ANOMALIA CF</span>

                        <span v-if="typeof slotProps.values.item.isconi2activityerrorflag !== 'undefined' && slotProps.values.item.isconi2activityerrorflag === 'Si'" class="badge pill bg-danger mb-1" style="font-size: 0.9em;"><i class="fas fa-window-close text-white"></i> ANOMALIA DISCIPLINE</span>
                    </div>
                </td>
            </template>

            <template v-slot:item-actions="slotProps">
                <td class="text-nowrap pr-0 text-right">
                    <a href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-info mr-3'" title="Carica Tesserato" alt="Carica Tesserato" @click.prevent="caricaTesserato(slotProps.values.item.id)">
                        <i class="fas fa-upload"></i>
                    </a>

                    <a v-if="slotProps.values.item.can_be_modified" href="#" @click.prevent="slotProps.values.doItemAction({ name: 'edit', label: 'Modifica', url: 'edit' }, slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Modifica" alt="Modifica">
                        <i class="fas fa-pencil-alt"></i>
                    </a>

                    <a v-if="slotProps.values.item.can_be_deleted && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" href="#" @click.prevent="showDeleteEnrolledUserModal(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Elimina" alt="Elimina">
                        <i class="fas fa-trash-alt"></i>
                    </a>

                    <b-modal v-if="slotProps.values.item.can_be_deleted && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" ref="deleteEnrolledUserModal" size="xl" hide-footer title="Elimina Tesserato">
                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                            <SmartForm
                                ref="deleteEnrolledUserForm"
                                base_url="/sportingclub/enrolled-user"
                                base_path="/sportingclub/enrolled-user"
                                form_type="delete"
                                :redirect_after_save="false"
                                :item_id="iddeleteenrolleduser"
                            >
                                <template v-slot:title>
                                    <a href="#" @click.prevent="closeDeleteEnrolledUserModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                        <i class="fas fa-window-close icon-sm"></i> Chiudi
                                    </a>
                                </template>
                                <template v-slot:toolbar="slotProps">
                                    <div class="d-flex flex-wrap justify-content-end">

                                        <a href="#" @click.prevent="confirmDeleteEnrolledUser(slotProps.values.id)" class="mt-2 btn btn-danger font-weight-bolder">
                                            <i class="fas fa-trash-alt"></i> Conferma Eliminazione
                                        </a>
                                    </div>
                                </template>
                                <template v-slot:footer="slotProps">
                                    <div class="card-footer border-0 py-5">
                                        <div class="d-flex flex-wrap justify-content-between">
                                            <a href="#" @click.prevent="closeDeleteEnrolledUserModal" class="mt-2 btn btn-danger font-weight-bolder mr-2">
                                                <i class="fas fa-window-close icon-sm"></i> Chiudi
                                            </a>

                                            <a href="#" @click.prevent="confirmDeleteEnrolledUser(slotProps.values.id)" class="mt-2 btn btn-danger font-weight-bolder">
                                                <i class="fas fa-trash-alt"></i> Conferma Eliminazione
                                            </a>
                                        </div>
                                    </div>
                                </template>
                            </SmartForm>
                        </b-overlay>
                    </b-modal>

                    <a href="#" @click.prevent="slotProps.values.doItemAction({ name: 'view', label: 'Dettagli', url: 'view' }, slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Visualizza" alt="Visualizza">
                        <i class="fas fa-eye"></i>
                    </a>

                    <a v-if="slotProps.values.item.idenrolledstate === 'Inserito - Da Attivare' && $store.getters.currentUser.usertype !== 'Sodalizio'" href="#" @click.prevent="attivaTesserato(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Attiva" alt="Attiva">
                        <i class="fas fa-star"></i>
                    </a>

                    <a v-if="slotProps.values.item.can_handle_insurance && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Comitato')" href="#" @click.prevent="showHandleInsuranceEnrolledUser(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-warning mr-3" title="Gestione Assicurazioni" alt="Gestione Assicurazioni">
                        <i class="fas fa-suitcase"></i>
                    </a>

                    <b-modal v-if="slotProps.values.item.can_handle_insurance && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Comitato')" ref="handleInsuranceEnrolledUser" size="xl" hide-footer title="Gestione Assicurazioni Tesserato" centered>
                        <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                            <EnrolledUserInsurance :idenrolleduser="idinsuranceenrolleduser"></EnrolledUserInsurance>
                            <div class="d-flex justify-content-end">
                                <b-button class="mt-3" variant="danger" @click.prevent="hideHandleInsuranceEnrolledUser">Chiudi</b-button>
                            </div>
                        </b-overlay>
                    </b-modal>
                </td>
            </template>
        </SmartTable>
    </b-overlay>
</template>

<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import SmartForm from "@/view/components/SmartForm.vue";
 import EnrolledUserInsurance from "@/view/components/EnrolledUserInsurance.vue";
 import ApiService from "@/core/services/api.service";
 import Swal from 'sweetalert2';

 export default {
     props: ['idsportingclub', 'annualityid'],

     data() {
         return {
             loading: false,
             iddeleteenrolleduser: null,
             idinsuranceenrolleduser: null,
         };
     },

     components: {
         SmartTable,
         SmartForm,
         EnrolledUserInsurance,
     },

     created() {
     },

     computed: {
     },

     mounted() {
     },

     methods: {
         closeModalTesseramenti() {
             this.$refs.tesseramentiRicerca.refresh();
         },

         showDeleteEnrolledUserModal(id) {
             this.iddeleteenrolleduser = id;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['deleteEnrolledUserModal'].show();
             }, 200);
         },

         closeDeleteEnrolledUserModal() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['deleteEnrolledUserModal'].hide();
             }, 200);
         },

         confirmDeleteEnrolledUser(id) {
             Swal.fire({
                 title: 'Conferma eliminazione tesserato',
                 text: "Sei sicuro di voler eliminare il tesserato?",
                 icon: 'error',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
                 confirmButtonColor: '#F64E60',

                 input: 'checkbox',
                 inputValue: 1,
                 inputPlaceholder: 'Restituisci la tessera al sodalizio',

             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/sportingclub/enrolled-user/destroy';

                     ApiService.post(url, {id: id, restore_card: result.value})
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Il tesserato è stato eliminato correttamente', {
                                           title: 'Tesserato eliminato corretamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.closeDeleteEnrolledUserModal();
                                       this.$refs.tesseramentiinbozza.refresh();
                                       this.$refs.tesseramentiRicerca.refresh();
                                       this.$refs.tesseratoForm.getOptions();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si è verificato un errore nell\'eliminazione del tesserato.', {
                                           title: 'Errore nell\'eliminazione del tesserato.',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }

                                   this.loading = false;
                               })
                               .catch(({ response }) => {
                                   this.$bvToast.toast('Si è verificato un errore nell\'eliminazione del tesserato.', {
                                       title: 'Errore nell\'eliminazione del tesserato',
                                       variant: 'danger',
                                       solid: true
                                   });
                                   console.log(response);

                                   this.loading = false;
                               })
                 }
             })
         },

         attivaTesserato(id) {
             Swal.fire({
                 title: 'Conferma attivazione tesserato',
                 text: "Sei sicuro di voler attivare il tesserato?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/sportingclub/activate-enrolled-user';

                     ApiService.post(url, {idenrolleduser: id})
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Il tesserato è stato attivato correttamente', {
                                           title: 'Tesserato attivato corretamente',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.$refs.tesseramentiinbozza.refresh();
                                       this.$refs.tesseramentiRicerca.refresh();
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si è verificato un errore nell\'attivazione del tesserato.', {
                                           title: 'Errore nell\'attivazione del tesserato.',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }

                                   this.loading = false;
                               })
                               .catch(({ response }) => {
                                   this.$bvToast.toast('Si è verificato un errore nell\'attivazione del tesserato.', {
                                       title: 'Errore nell\'attivazione del tesserato',
                                       variant: 'danger',
                                       solid: true
                                   });
                                   console.log(response);

                                   this.loading = false;
                               })
                 }
             })
         },

         showHandleInsuranceEnrolledUser(id) {
             this.idinsuranceenrolleduser = id;

             let vm = this;
             setTimeout(function() {
                 vm.$refs['handleInsuranceEnrolledUser'].show();
             }, 200);
         },

         hideHandleInsuranceEnrolledUser() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['handleInsuranceEnrolledUser'].hide();
             }, 200);
         },

         caricaTesserato(id) {
             Swal.fire({
                 title: 'Conferma Invio Tesserato',
                 text: "Sei sicuro di voler inviare il Tesserato?",
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let url = '/conimonitoring/send-enrolled-user';
                     let data = { idenrolleduser: id, idannuality: this.annualityid };

                     ApiService.post(url, data)
                               .then((response) => {
                                   if (response.data.status === 'OK') {
                                       this.$bvToast.toast('Invio messo correttamente in coda', {
                                           title: 'Invio messo correttamente in coda',
                                           variant: 'success',
                                           solid: true
                                       });

                                       this.$emit('update-monitoring');
                                   }

                                   if (response.data.status === 'KO') {
                                       this.$bvToast.toast('Si sono verificati degli errori nella messa in coda dell\'invio.', {
                                           title: 'Errore nella messa in coda dell\'invio',
                                           variant: 'danger',
                                           solid: true
                                       });
                                   }
                               })
                               .catch(({response}) => {
                                   console.log(response);
                               });

                     this.loading = false;
                 }
             });
         },
     }
 };
</script>

<style>
 .border-bluenavy .card-header {
	 border-bottom: 1px solid #143D67;
 }
</style>
